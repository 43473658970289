exports.components = {
  "component---3963984967-me-portfolio-minimal-src-templates-article-listing-index-tsx": () => import("./../../../node_modules/.pnpm/gatsby-theme-portfolio-minimal@4.5.0_@babel+core@7.21.4_gatsby-source-contentful@8.8.0_gatsby_ayor6w5272l2hhn6u5lrvgfxti/node_modules/gatsby-theme-portfolio-minimal/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---3963984967-me-portfolio-minimal-src-templates-article-listing-index-tsx" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-pnpm-gatsby-theme-portfolio-minimal-4-5-0-babel-core-7-21-4-gatsby-source-contentful-8-8-0-gatsby-ayor-6-w-5272-l-2-hhn-6-u-5-lrvgfxti-node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx": () => import("./../../../node_modules/.pnpm/gatsby-theme-portfolio-minimal@4.5.0_@babel+core@7.21.4_gatsby-source-contentful@8.8.0_gatsby_ayor6w5272l2hhn6u5lrvgfxti/node_modules/gatsby-theme-portfolio-minimal/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-pnpm-gatsby-theme-portfolio-minimal-4-5-0-babel-core-7-21-4-gatsby-source-contentful-8-8-0-gatsby-ayor-6-w-5272-l-2-hhn-6-u-5-lrvgfxti-node-modules-gatsby-theme-portfolio-minimal-src-pages-404-tsx" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-acceptable-use-policy-js": () => import("./../../../src/pages/legal/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-legal-acceptable-use-policy-js" */),
  "component---src-pages-legal-api-terms-of-service-js": () => import("./../../../src/pages/legal/api-terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-api-terms-of-service-js" */),
  "component---src-pages-legal-developer-policy-js": () => import("./../../../src/pages/legal/developer-policy.js" /* webpackChunkName: "component---src-pages-legal-developer-policy-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

