module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.8.0_gatsby-plugin-sharp@5.8.1_gatsby@5.9.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":660,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@5.9.0_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fedified · Discover great content on Mastodon and beyond","short_name":"Fedified","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"./assets/icons/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe1292836990711c227b0789d3f9c271"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.8.0_gatsby@5.9.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-theme-portfolio-minimal@4.5.0_@babel+core@7.21.4_gatsby-source-contentful@8.8.0_gatsby_ayor6w5272l2hhn6u5lrvgfxti/node_modules/gatsby-theme-portfolio-minimal/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://fedified.com","manifestSettings":{"favicon":"./assets/icons/favicon.svg","siteName":"Fedified · Discover great content on Mastodon and beyond","shortName":"Fedified","startUrl":"/","backgroundColor":"#FFFFFF","themeColor":"#000000","display":"minimal-ui"},"contentDirectory":"./content","blogSettings":{"path":"/faq","usePathPrefixForArticles":false}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.9.0_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.0.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
